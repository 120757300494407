<template>
  <section style="min-height: 500px; margin: 5px;padding: 10px" >
    <el-header style="display:flex;height: 50px; background-color: #222933;margin-left:35px;margin-right: 35px;">
      <router-link :to="{ path: '/' }" style="line-height:50px;font-size:1.2rem;margin-right:10px"><i class="el-icon-back" /> 返回 |</router-link>
      <el-breadcrumb separator="/" style="line-height:50px;font-size: 1.2rem">
        <el-breadcrumb-item :to="{ path: '/' }">
          首页</el-breadcrumb-item>
        <el-breadcrumb-item>系统设置</el-breadcrumb-item>
        <el-breadcrumb-item>用户管理</el-breadcrumb-item>
      </el-breadcrumb>
    </el-header>

    <el-container>
      <el-main>
        <router-view />
      </el-main>
    </el-container>
  </section>
</template>

<script>

export default {
  name: 'UserList',
  data() {
    return {
      
    }
  },
  computed: {

  },
  watch: {

  },
  created() {
  },
  mounted() {},
  methods: {
    
  }
}
</script>
<style lang="scss" scoped>
  .text {
    font-size: 14px;
  }

  .item {
    margin-bottom: 18px;
  }

  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }
  .clearfix:after {
    clear: both
  }

  .box-card {
    width: auto;
    margin: 2px;
  }
</style>
